/* Global Styles */
/* Global Styles */
html,
body {
  height: 100%; /* Ensure the body and html take the full height of the viewport */
  margin: 0;
  padding: 0;
  background-color: #000000;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%; /* Ensure the App container takes the full height of the viewport */
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  background-color: #000000;
  color: #fbfbfb;
}

h1,
h2,
h3 {
  color: #333;
}

p {
  font-size: 1.1em;
  line-height: 1.6;
  margin: 0 0 20px 0;
}

/* Center all the main sections */
section {
  margin: 40px 20px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Header Styles */
.header {
  padding: 30px 20px;
  text-align: center;
}

.header h1 {
  font-size: 3em;
  margin: 0;
}

.header p {
  font-size: 1.2em;
  margin-top: 10px;
  font-weight: 300;
}

/* App Description Styles */
.app-description {
  border-radius: 10px;
  padding: 30px 20px;
  margin: 40px 0;
}

.app-description h2 {
  font-size: 2em;
  margin-bottom: 15px;
}

.app-description p {
  font-size: 1.1em;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

/* Screenshot Gallery Styles */
.screenshot-gallery {
  padding: 10px;
  text-align: center;
}

.screenshots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.screenshots img {
  width: 100%;
  max-width: 250px; /* You can adjust this value as per your design */
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .screenshots img {
    max-width: 200px; /* Reduce size on smaller screens */
  }
}

@media (max-width: 480px) {
  .screenshots img {
    max-width: 100%; /* Full width on extra small screens */
  }
}

/* Download Button Styles */
.download-section {
  margin-top: 40px;
}

.download-button {
  background-color: #007aff;
  color: #fff;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 1.2em;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #005bb5;
}

/* Footer Styles */
.footer {
  background-color: #000000;
  color: white;
  padding: 20px;
  text-align: center;
}

.footer p {
  margin-bottom: 10px;
}

.footer a {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
